import PropTypes from "prop-types"
import React from 'react'
import { useTranslation } from 'react-i18next'
import useTranslateTexts from '../../../Context/useTranslateTexts'

function SelectMethod({ setMethod, resetMethods, setResetMethods }) {

    const texts = {
        selectMethod: 'Veuillez selectionner une méthode',
        forgotPassword: "Forgot Password",
        back: "Back",
        ema: 'Your Email',
        backup: 'Backup Codes',
        recov: 'Recovery Email',
        totp: 'TOTP'
    }

    const translatedTexts = useTranslateTexts(texts);
console.log('first',resetMethods)
    return (
        <div>
            <h1>{translatedTexts.forgotPassword}</h1>
            <p className='form-label'>{translatedTexts.selectMethod}</p>
            <button className='text-black fw-normal form-control my-1 border border-dark hover:bg-gray' type='button' onClick={() => setMethod("email")}>
                <div style={{ display: "grid", gridTemplateColumns: "30px auto", gap: "10px", width: "fit-content", marginInline: "auto" }}>
                    <EmailIcon width={20} height={20} />
                    <p className='m-0' style={{ textAlign: "left" }}>
                        {translatedTexts.ema}
                    </p>
                </div>
            </button>
            {
                resetMethods?.totpEnabled &&
                <button className='text-black fw-normal form-control my-1 border border-dark hover:bg-gray' type='button' onClick={() => setMethod("totp")}>
                    <div style={{ display: "grid", gridTemplateColumns: "30px auto", gap: "10px", width: "fit-content", marginInline: "auto" }}>
                        <TOTPIcon width={20} height={20} />
                        <p className='m-0' style={{ textAlign: "left" }}>
                            {translatedTexts.totp}
                        </p>
                    </div>
                </button>
            }

            {
                resetMethods?.nbBackupCodes > 0 &&
                <button className='text-black fw-normal form-control my-1 border border-dark hover:bg-gray' type='button' onClick={() => setMethod("backup-codes")}>
                    <div style={{ display: "grid", gridTemplateColumns: "30px auto", gap: "10px", width: "fit-content", marginInline: "auto" }}>
                        <BackupCodesIcon width={20} height={20} />
                        <p className='m-0' style={{ textAlign: "left" }}>
                            {translatedTexts.backup}
                        </p>
                    </div>
                </button>
            }
            {
                resetMethods?.recoveryEmail &&
                <button className='text-black fw-normal form-control my-1 border border-dark hover:bg-gray' type='button' onClick={() => setMethod("recovery-email")}>
                    <div style={{ display: "grid", gridTemplateColumns: "30px auto", gap: "10px", width: "fit-content", marginInline: "auto" }}>
                        <EmailIcon width={20} height={20} />
                        <p className='m-0' style={{ textAlign: "left" }}>
                            {translatedTexts.recov}
                        </p>
                    </div>
                </button>
            }
            <button type='button' onClick={() => { setResetMethods(null) }} style={{ all: "unset" }} className='hover:underline fw-bold d-block' >{translatedTexts.back}</button>
        </div>
    )
}

export default SelectMethod

SelectMethod.propTypes = {
    setMethod: PropTypes.func,
    resetMethods: PropTypes.object,
    setResetMethods: PropTypes.func
}

SmsIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    props: PropTypes.object
}

function SmsIcon({ width = 20, height = 20, ...props }) {
    return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" width={width} height={height} {...props} >
        <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 0 1 .865-.501 48.172 48.172 0 0 0 3.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" />
    </svg>

}

TOTPIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    props: PropTypes.object
}

function TOTPIcon({ width = 20, height = 20, ...props }) {
    return <svg version="1.1" viewBox="0 0 432.46 543.09" width={width} height={height} {...props} >
        <g>
            <path d="M432.46,244.75c0,2.83,0,5.66,0,8.49c-0.08,0.51-0.18,1.03-0.24,1.54c-0.48,4.21-0.75,8.46-1.47,12.64
       c-2.98,17.29-6.85,34.39-11.56,51.29c-9.2,32.98-21.56,64.69-37.94,94.79c-16.84,30.95-37.41,59.05-63.39,83.02
       c-11.84,10.93-24.71,20.47-38.76,28.39c-10.55,5.95-21.56,10.78-33.25,14.03c-16.05,4.46-32.28,5.43-48.69,2.46
       c-13.37-2.42-25.96-7.12-38-13.33c-19.07-9.83-35.9-22.73-51.14-37.74c-21.97-21.63-39.89-46.33-54.87-73.2
       c-16.12-28.91-28.49-59.4-37.84-91.13C9.89,307.62,5.6,289,2.11,270.18c-2.43-13.12-2.92-26.24-0.6-39.43
       c3.67-20.84,12.73-39.05,26.91-54.68c13.92-15.34,30.81-26.26,50.37-33.04c1.51-0.52,1.82-1.22,1.83-2.72
       c0.05-5.79,0.04-11.59,0.5-17.36c1.3-16.12,5.65-31.49,12.7-46.01c13.01-26.77,32.74-47.19,58.99-61.23
       c15.26-8.16,31.54-13.06,48.73-14.92c8.95-0.97,17.91-1.07,26.88-0.23c10.51,0.99,20.8,3.01,30.8,6.42
       c23.81,8.11,44,21.61,60.33,40.79c13.88,16.31,23.38,34.89,28.5,55.68c3.03,12.31,4.22,24.83,3.75,37.49
       c-0.04,1.14,0.34,1.57,1.38,1.94c3.37,1.21,6.75,2.42,10.02,3.86c14.74,6.51,27.74,15.55,38.86,27.22
       c10.25,10.75,18.15,23.01,23.42,36.93c3.24,8.55,5.38,17.37,6.34,26.47C432.07,239.83,432.24,242.29,432.46,244.75z M332.65,415.58
       c0.25-0.37,0.55-0.8,0.83-1.24c19.35-30.33,33.43-63.03,43.82-97.39c5.52-18.25,9.78-36.79,13.2-55.54
       c2.07-11.35,1.55-22.56-2.21-33.5c-6.17-17.94-18.13-31.04-34.45-40.3c-13.58-7.71-28.28-11.1-43.86-11.09
       c-62.53,0.03-125.06,0.02-187.59,0.01c-8.67,0-17.21,0.95-25.54,3.44c-19.83,5.91-35.83,17.07-46.69,34.91
       c-8.14,13.37-11.15,28.01-8.63,43.43c1.94,11.89,4.54,23.69,7.32,35.42c5.37,22.65,12.63,44.72,21.7,66.17
       c8,18.93,17.32,37.18,28.43,54.49c0.27,0.42,0.57,0.83,0.78,1.14c28.58-40.15,67.02-62.27,116.5-62.28
       C265.75,353.22,304.11,375.49,332.65,415.58z M121.01,135.71c63.63,0,126.86,0,190.36,0c-1.36-35.19-16.3-62.54-46.13-81.24
       c-30.17-18.91-70.09-18.5-100.17,1.27C136.51,74.52,122.24,101.5,121.01,135.71z M214.41,393.73c-2.29,0.17-6.41,0.27-10.48,0.8
       c-20.4,2.65-38.33,10.8-53.63,24.58c-9.68,8.72-17.31,18.98-22.98,30.72c-0.43,0.9-0.37,1.48,0.34,2.19
       c2.29,2.33,4.47,4.78,6.78,7.1c10.98,11.06,22.89,20.96,36.39,28.84c9.78,5.71,19.99,10.38,31.14,12.75
       c7.58,1.62,15.21,2.17,22.92,1.05c11.52-1.68,22.17-5.83,32.35-11.34c18.27-9.88,33.71-23.26,47.63-38.52
       c0.56-0.61,0.66-1.11,0.29-1.87c-5.71-11.97-13.44-22.47-23.38-31.23C263.07,402.32,241.19,393.97,214.41,393.73z"/>
            <path d="M216.27,196.91c37.45,0.07,67.92,30.54,67.79,67.82c-0.12,36.91-29.33,67.66-67.32,67.83
       c-38.19,0.17-68.46-30.14-68.39-67.96C148.42,227.2,178.88,196.84,216.27,196.91z M216.36,237.61
       c-14.82-0.21-27.08,11.89-27.29,26.95c-0.21,14.85,11.78,27.02,26.91,27.33c14.79,0.3,27.15-11.85,27.35-26.88
       C243.54,250.07,231.49,237.83,216.36,237.61z"/>
        </g>
    </svg>

}

BackupCodesIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    props: PropTypes.object
}

function BackupCodesIcon({ width = 20, height = 20, ...props }) {
    return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={width} height={height} color="#000000" fill="none" {...props}>
        <path d="M13.4082 16.6677C13.4082 16.6677 14.0332 16.6677 14.6582 18.001C14.6582 18.001 16.6435 14.6677 18.4082 14.001" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M16.9434 7.00098H16.9524" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M11.9434 7.00098H11.9524" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6.94336 7.00098H6.95234" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7.89193 11.968H5.00845C3.34693 11.968 2 10.6249 2 8.96802V4.99805C2 3.34119 3.34693 1.99805 5.00845 1.99805H18.9916C20.6531 1.99805 22 3.34119 22 4.99805V9.12895" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M21.9996 16.001C21.9996 12.6873 19.3057 10.001 15.9827 10.001C12.6597 10.001 9.96582 12.6873 9.96582 16.001C9.96582 19.3147 12.6597 22.001 15.9827 22.001C19.3057 22.001 21.9996 19.3147 21.9996 16.001Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
}

EmailIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    props: PropTypes.object
}

function EmailIcon({ width = 20, height = 20, ...props }) {
    return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" width={width} height={height} {...props}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
    </svg>

}

