import React, { useEffect, useState } from 'react'
import Chart from 'react-apexcharts';
import { Card } from 'reactstrap';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Axios } from '../../../config';
import useTranslateTexts from '../../../Context/useTranslateTexts';

export default function TrafficChart() {
    const [input, setInput] = useState({
        date: (()=>{const date = new Date(); date.setMinutes(0); date.setSeconds(0,0); return date})(),
        type: "day"
    })
    const [loading, setLoading] = useState({onlineUsersHistory: false})
    const [onlineUsersHistory, setOnlineUsersHistory] = useState([])

    async function getOnlineUsersHistory(){
        if (loading.onlineUsersHistory) return
        
        setLoading(prev => ({...prev, onlineUsersHistory: true}))
        try{
          const res = await Axios.get("/user/getOnlineUsersHistory", {
            params: {
                date: input.date,
                type: input.type
            }
          })
          console.log(res.data)
          setOnlineUsersHistory(res.data)
          
        } catch (err){
          console.log(err)
        }
        setLoading(prev => ({...prev, onlineUsersHistory: false}))
      }

      useEffect(()=>{
        getOnlineUsersHistory()
      },[input.type, input.date])

    const series = onlineUsersHistory?.map?.(el => ({x: new Date(el.date).getTime(), y: el.count}))
    
    const chartOptions = {
        type: 'line',
        height: "300px",
        // options: {
        //     dataLabels: {
        //         formatter(val, opts) {
        //             const name = opts.w.globals.labels[opts.seriesIndex]
        //             return [name, val.toFixed(1) + '%']
        //         },  
        //     },
        // },
        options: {
            yaxis: {
                min: 0
            },
            xaxis: {
                type: "datetime",
                labels: {
                    datetimeUTC: false
                }
            },
            tooltip: {
                shared: false,
                y: {
                    title: {
                        formatter: function(seriesName, opts) {
                            const currentX = opts.w.config.series[0].data[opts.dataPointIndex].x
                            return new Date(currentX).toLocaleString("en-UK", {dateStyle: 'short', timeStyle: "short"})
                        }
                    }
                }
            },
            stroke: {
                curve: 'smooth'
            },
            chart: {
                type: 'area',
                stacked: false,
                height: 350,
                zoom: {
                  type: 'x',
                  enabled: true,
                  autoScaleYaxis: true
                },
                toolbar: {
                  autoSelected: 'zoom'
                }}
        },
        series: [{
            name: "Number of users",
            data: series
        }],
        markers: {
            size: 0
        },
        
    };
console.log(input)

    const texts = {
        websiteTraffic: "Trafic du site",
        hour: "Heure",
        day: "Jour",
        week: "Semaine",
        month: "Mois",
        year: "Année"
    }
    const translatedTexts = useTranslateTexts(texts);


  return (
    <Card className="p-3 w-100">
        <h4 className='m-0 fw-bold text-black'>{translatedTexts.websiteTraffic}</h4>
        <div className='d-flex gap-2'>
            {
                input.type === "hour" ?
                <div style={{width: "fit-content", zIndex: 10}}>
                    <DatePicker
                        selected={input.date}
                        onChange={(date) => setInput(prev => ({...prev, date}))}
                        showTimeSelect
                        timeIntervals={60} // This will show only the hours
                        dateFormat="dd MMMM yyyy h:mm aa"
                        timeFormat="HH:mm" // This will format the time display to hours and minutes
                        maxDate={new Date()}
                        minTime={(()=>{const d = new Date(); d.setHours(0, 0, 0, 0); return d;})()}
                        maxTime={input.date.getDate() < new Date().getDate() ? (()=>{const d = new Date(); d.setHours(23, 59, 59, 999); return d;})() : new Date()}
                        />
                </div> :
                input.type === "day" ?
                <div style={{width: "fit-content", zIndex: 10}}>
                    <DatePicker
                        selected={input.date}
                        onChange={(date) => setInput(prev => ({...prev, date}))}
                        dateFormat="dd MMMM yyyy"
                        maxDate={new Date()}
                        />
                </div> :
                input.type === "week" ?
                <div style={{width: "fit-content", zIndex: 10}}>
                    <DatePicker
                        selected={input.date}
                        onChange={(date) => setInput(prev => ({...prev, date}))}
                        dateFormat="I/yyyy"
                        showWeekPicker
                        maxDate={new Date()}
                        />
                </div> :
                input.type === "month" ?
                <div style={{width: "fit-content", zIndex: 10}}>
                    <DatePicker
                        selected={input.date}
                        onChange={(date) => setInput(prev => ({...prev, date}))}
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        maxDate={new Date()}
                        />
                </div> :
                <div style={{width: "fit-content", zIndex: 10}}>
                    <DatePicker
                        selected={input.date}
                        onChange={(date) => setInput(prev => ({...prev, date}))}
                        showYearPicker
                        dateFormat="yyyy"
                        maxDate={new Date()}
                        />
                </div>
            }
            <select style={{width: "100px"}} value={input.type} onChange={(e)=>{setInput(prev => ({...prev, type: e.target.value}))}}>
                <option value="day">{translatedTexts.day}</option>
                <option value="week">{translatedTexts.week}</option>
                <option value="month">{translatedTexts.month}</option>
                <option value="year">{translatedTexts.year}</option>
            </select>
            {
                loading.onlineUsersHistory &&
                <div className='loader-xs loader-black' ></div>
            }
        </div>
        <Chart {...chartOptions}  />
    </Card>
  )
}

TrafficChart.propTypes = {
    onlineUsersHistory: PropTypes.array
}