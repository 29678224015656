import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Axios } from '../../../config';

export const fetchMalwares = createAsyncThunk('malwares/fetchMalwares', async (page) => {
  try {
    let res = await Axios.get(`/malware/logs?page=${page}`);
    return res.data;
  } catch (error) {
    throw Error(error.response.data);
  }
});

export const scan = createAsyncThunk('malwares/scan', async (_, { rejectWithValue }) => {
    try {
      const response = await Axios.get('/malware/scan');
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  });
  
  export const deepScan = createAsyncThunk('malwares/deepScan', async (_, { rejectWithValue }) => {
    try {
      const response = await Axios.get( '/malware/scan?deep_scan=true');
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  });
  
  export const clean = createAsyncThunk('malwares/clean', async (_, { rejectWithValue }) => {
    try {
      const response = await Axios.get('/malware/clean');
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  });
  export const stat = createAsyncThunk('malwares/stat', async (_, { rejectWithValue }) => {
    try {
      const response = await Axios.get('/malware/stat');
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  });
export const malwaresSlice = createSlice({
  name: 'malwares',
  initialState: {
    data: [],
  dataMalware:[],
  dataScan:[],
    isSuccess: false,
    isError: false,
    isFetching: false,
    currentPage: 1,
  },
  reducers: {
    clearMalwareData: (state) => {
      Object.assign(state, {
        data: [],
  dataMalware:[],
  dataScan:[],
    isSuccess: false,
    isError: false,
    isFetching: false,
    currentPage: 1,
      });}
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMalwares.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchMalwares.fulfilled, (state, { payload }) => {
        state.isSuccess = true;
        state.data = payload;
        state.currentPage += 1;
        state.isFetching = false;
      })
      .addCase(fetchMalwares.rejected, (state) => {
        state.isError = true;
        state.isFetching = false;
      })
       .addCase(scan.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(scan.fulfilled, (state, { payload }) => {
        // console.log("frttttttttt",payload)
        state.isFetching = false;
        state.isSuccess = true;
        state.data = payload
        // .data.map((d) => {
        //   d.date = new Date(d.created_at).toLocaleDateString();
        //   return d;
        // });
      })
      .addCase(scan.rejected, (state) => {
        state.isFetching = false;
        state.isError = true;
      })
      .addCase(deepScan.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(deepScan.fulfilled, (state, { payload }) => {
        //  console.log("frttttttttt",payload)
        state.isFetching = false;
        state.isSuccess = true;
        state.data = payload?.data?.map((d) => {
          d.date = new Date(d.created_at).toLocaleDateString();
          return d;
        });     })
      .addCase(deepScan.rejected, (state) => {
        state.isFetching = false;
        state.isError = true;
      })
      .addCase(clean.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(clean.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.data=payload;
      })
      .addCase(clean.rejected, (state) => {
        state.isFetching = false;
        state.isError = true;
        // Update the state as needed based on the error response
      })
      .addCase(stat.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(stat.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.data=payload;
      })
      .addCase(stat.rejected, (state) => {
        state.isFetching = false;
        state.isError = true;
        // Update the state as needed based on the error response
      });
  },
});
export const { clearMalwareData } = malwaresSlice.actions;


export default malwaresSlice.reducer;
