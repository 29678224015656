import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

i18n
  .use(Backend) // Charge les ressources de traduction via http
  .use(LanguageDetector) // Détecte la langue de l'utilisateur
  .use(initReactI18next) // initialisation de react-i18next
  .init({
    fallbackLng: 'en', // Langue de repli
    debug: true,
    interpolation: {
      escapeValue: false, // Pas d'échappement des valeurs
    },
    detection: {
      order: ['cookie', 'querystring', 'localStorage', 'htmlTag'], // Exclure 'navigator' de l'ordre de détection
      caches: ['cookie'], // Méthodes de cache pour se souvenir de la langue choisie
      cookieName: 'i18next', // Nom du cookie pour stocker la langue
    }
  });

export default i18n;
