import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Axios } from "../../../config";

export const traficVisitorsInfo = createAsyncThunk('info/fetchTrafic', async ({userRole,userId,page,startDate,endDate}) => {
    try {
      let res = await Axios.get(`/log/trafic?page=${page}&startDate=${startDate}&endDate=${endDate}&userRole=${userRole}&userId=${userId}`);
      return res.data;

    } catch (error) {
      throw Error(error.response.data);
    }
  });

export const deleteListVisitorsInfo = createAsyncThunk('info/deleteTrafic', async ({userId}) => {
    try {
      let res = await Axios.delete(`/log/delTrafic?userId=${userId}`);
      const data = await res.data;
  
      return data;
    } catch (error) {
      throw Error(error.response.data);
    }
  });

export const traficVisitorsSlice = createSlice({
    name: 'traficVisitors',
    initialState: {
      isSuccess: false,
      isError: false,
      isFetching: false,
      traficInfo: null,
      errorMessage: '',
      count:null,
      data: [], OSs: [], browsers: [],domaines:[]
    },
    reducers: {
      clearTraficVisitorsData: (state) => {
        Object.assign(state, {
          isSuccess: false,
          isError: false,
          isFetching: false,
          traficInfo: null,
          errorMessage: '',
          count: null,
          data: [],
          OSs: [],
          browsers: [],
          domaines: [],
          clients:[],
        });}
    },
    extraReducers: (builder) => {
      builder
        .addCase(traficVisitorsInfo.pending, (state) => {
          state.isFetching = true;
          // state.count=0;
          state.isError = false;
          state.isSuccess = false;})
      
        .addCase(traficVisitorsInfo.fulfilled, (state, { payload }) => {
          console.log('trfic',payload)
          state.loading = false;
          state.data = payload.data;
          state.OSs = payload.OSs;
          state.clients = payload.clients;
          state.browsers = payload.browsers;
          state.domaines=payload.domaines;
          })
          .addCase(traficVisitorsInfo.rejected, (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.isSuccess = false;
          })
        
          .addCase(deleteListVisitorsInfo.pending, (state) => {
            state.isFetching = true;
            state.isError = false;
            state.isSuccess = false;})
        
          .addCase(deleteListVisitorsInfo.fulfilled, (state, { payload }) => {
              state.traficInfo = payload;
              state.isFetching = false;
              state.isSuccess = true;
              state.isError = false;
            })
            .addCase(deleteListVisitorsInfo.rejected, (state, { payload }) => {
              state.isFetching = false;
              state.isError = true;
              state.isSuccess = false;
            })
        }})
        export const { clearTraficVisitorsData } = traficVisitorsSlice.actions;


export default traficVisitorsSlice.reducer;
