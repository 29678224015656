import React, { useEffect, useState } from 'react';
import 'react-phone-number-input/style.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Input
} from 'reactstrap';
import useTranslateTexts from '../../../Context/useTranslateTexts';
import { Axios } from '../../../config';
import {
  profileUser
} from '../../../store/apps/user/userSlice';
import ConfirmModal from '../ConfirmModal';

const PhoneOTP = () => {
  const { users, isSuccessPass, isErrorPass } = useSelector((state) => state.userReducer);

  const dispatch = useDispatch();
  const [successAlert, setSuccessAlert] = useState(null);
  const [errorAlert, setErrorAlert] = useState(null);
  const [showAlertErrorConnexion, setShowAlertErrorConnexion] = useState(null);
  const [errors, setErrors] = useState('');
  const navigate = useNavigate();
  const [OTP, setOTP] = useState(null);
  const [code, setCode] = useState("")
  const [error, setError] = useState("")
  const [codeSent, setCodeSent] = useState(false)
  const [showAddEmail, setShowAddEmail] = useState(false)
  const [input, setInput] = useState({
    recoveryEmail: "",
    code: "",
  })
  const [loading, setLoading] = useState({
    addEmail: false,
    verifyEmail: false,
    removeEmail: false,
  })
  const [showDeleteEmailModal, setShowDeleteEmailModal] = useState(false);

  useEffect(() => {
    
    if (isSuccessPass) {
      setSuccessAlert(translatedTexts.alertS);
      setErrorAlert(null);

      setTimeout(() => {
        setSuccessAlert(null);
      }, 3000);
    } else if (isErrorPass) {
      setErrorAlert(translatedTexts.alertE );
      setTimeout(() => {
        setErrorAlert(null);
      }, 3000);
    }
  }, [isErrorPass, isSuccessPass]);
  const texts = {
    recoveryEmail: 'Email de récupération',
    recoveryEmailDescription: "Vous pouvez utiliser un email de récupération pour récupérer l'accès à votre compte si vous perdez l'accés à votre email principale.",
    enabled: 'Activé',
    notEnabled: "Désactivé",
    enable: "Activer",
    disable: "Désactiver",
    downloadAuthenticator: "Téléchargez une application d'authentification comme Microsoft Authenticator, Authy, etc. Et scannez ce code QR.",
    enterCode: "Entrez le code envoyé à votre email",
    enterRecoveryEmail: "Entrez votre email de récupération",
    cannotEnterCode: "Vous ne pouvez pas scannez le code QR?",
    verify: "Vérifier",
    secretCode: "Entrer ce code secret dans l'application d'authentification:",
    security: "Sécurité",
    noRecoveryEmail: "Aucun email de récupération enregistré",
    addRecoveryEmail: "Ajouter un email de récupération",
    sendCode: "Envoyer un code de vérification",
    invalidPhoneNumber: "Numéro de téléphone invalide",
    email: "Email",
    confsupp:"Voulez-vous vraiment supprimer l'email de récupération:",
    erreurm:"Vous ne pouvez pas utiliser votre email principal comme email de récupération",
    emailrec:"Veuillez entrer un email de récupération",
    btnA:'Annuler',
    btnsupp:'Supprimer'
        };
  const translatedTexts = useTranslateTexts(texts);

  async function handleSubmit(e){
    e.preventDefault()

    if (loading.verifyEmail) return;
    
    setLoading(prev => ({...prev, verifyEmail: true}))
    try{
      const res = await Axios.post('/user/verifyRecoveryEmail', { otp: input.code });
      console.log("verifyTotp", res.data)
      setInput({recoveryEmail: "", code: ""})
      setCodeSent(false)
      setShowAddEmail(false)
      dispatch(profileUser())
    } catch(err){
      setError("Code invalide")
      console.log(err)
    }
    setLoading(prev => ({...prev, verifyEmail: false}))
  }

  async function addRecoveryEmail(e){
    e.preventDefault()
    
    setError("")

    if (loading.addEmail) return;

    if (input.recoveryEmail === users?.email){
      setError(translatedTexts.erreurm)
      return;
    }

    if (!input.recoveryEmail){
      setError(translatedTexts.emailrec)
      return;
    }
    
    setLoading(prev => ({...prev, addEmail: true}))
    try{
      const res = await Axios.post('/user/addRecoveryEmail', {recoveryEmail: input.recoveryEmail});
      setCodeSent(true)
    } catch(err){
      setError(err.response.data.msg);
      console.log(err)
    }
    setLoading(prev => ({...prev, addEmail: false}))

  }


  async function removeRecoveryEmail(){
    if (loading.removeEmail) return;

    setLoading(prev => ({...prev, removeEmail: true}))
    try{
      const res = await Axios.put('/user/removeRecoveryEmail');
      dispatch(profileUser())
    } catch(err){
      console.log(err)
    }
    setLoading(prev => ({...prev, removeEmail: false}))
  }
  
  return (
    <div>
      <ConfirmModal show={showDeleteEmailModal} toggle={()=>{ setShowDeleteEmailModal(prev => !prev) }} onConfirm={removeRecoveryEmail} confirmButtonText={translatedTexts.btnsupp}cancelButtonText={translatedTexts.btnA} loading={loading.removeEmail} >
        <p className="m-0 fw-bold text-black" style={{fontSize: "16px"}}>{translatedTexts.confsupp}</p>
        <p className="fw-bold text-black" style={{fontSize: "16px"}}>{users?.recoveryEmail}</p>
      </ConfirmModal>
      <div className="card p-3 rounded" style={{maxWidth:"700px"}}>
        <div className="">
          <h3 className='m-0 fw-bold text-black mb-2'>{translatedTexts.recoveryEmail}</h3>
          <p className='fw-normal'>{translatedTexts.recoveryEmailDescription}</p>
          <div className='d-flex align-items-center mb-3 gap-2'>
            <p className='m-0 px-2 py-1 border rounded fw-normal ' style={{width: "fit-content"}}>{users?.recoveryEmailVerified ? translatedTexts?.email + ": " + users?.recoveryEmail : translatedTexts?.noRecoveryEmail}</p>
            {
              users?.recoveryEmail ?
              <button type='button' className='d-flex align-items-center justify-content-center hover:bg-gray' style={{all: "unset", outline: "revert", height: "28px", width: "28px", borderRadius: "50%", background: "#eee" }} onClick={()=>{ setShowDeleteEmailModal(true) }}>
                <TrashIcon />
              </button> : ""
            }
          </div>
          {
            !users?.recoveryEmailVerified && !showAddEmail ?
            <button onClick={()=>{setShowAddEmail(true)}} type='button' className='d-flex align-items-center gap-2 btn border hover:bg-gray text-black' style={{background: "#eee"}}>
            <p className='m-0'>
            {translatedTexts?.addRecoveryEmail}
            </p>
            <AddCircleIcon />
          </button>
              : showAddEmail && !codeSent ?
              (
                <form onSubmit={addRecoveryEmail}>

                <div style={{maxWidth: "300px"}}>
                    <p className='m-0 mt-2 fw-normal'>{translatedTexts.enterRecoveryEmail}</p>
                    <Input type="email" name="email" placeholder={translatedTexts.email} value={input.recoveryEmail} onChange={(e)=>{setInput(prev => ({...prev, recoveryEmail: e.target.value}))}} />
                    {
                      error ?
                      <p className='m-0 text-danger'>{error}</p>
                      : ""
                    }
                    <div className='position-relative mt-2'>
                      <Button type="submit" className="btn-success w-100" >{translatedTexts.sendCode}</Button>
                      {
                        loading.addEmail ?
                        <div className="position-absolute" style={{top: "50%", right: "5px", transform: "translateY(-50%)"}}>
                          <div className='loader-sm' ></div>
                        </div>
                        : ""
                      }
                    </div>
                </div>
                    </form>
              )
              : showAddEmail && codeSent ?
              (
                <form style={{maxWidth: "300px"}} onSubmit={handleSubmit}>
                    <p className='mb-0 mt-4 form-control' style={{background: "#eee"}}>{input.recoveryEmail}</p>
                    <p className='m-0 fw-normal'>{translatedTexts.enterCode}</p>
                    <Input type="text" name="otp" placeholder="XXXXXX" value={input.code} onChange={(e)=>{setInput(prev => ({...prev, code: e.target.value}))}} />
                    {
                      error ?
                      <p className='m-0 text-danger'>{error}</p>
                      : ""
                    }
                    <div className='position-relative mt-2'>
                      <Button type="submit" className="btn-success w-100" >{translatedTexts.verify}</Button>
                      {
                        loading.verifyEmail ?
                        <div className="position-absolute" style={{top: "50%", right: "5px", transform: "translateY(-50%)"}}>
                          <div className='loader-sm' ></div>
                        </div>
                        : ""
                      }
                    </div>
                </form>

              )
              : ""

          }
        </div>
    </div>
  </div>
  );
};

export default PhoneOTP;

const AddCircleIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color="#000000" fill="none" {...props}>
    <path d="M12 8V16M16 12L8 12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12Z" stroke="currentColor" strokeWidth="1.5" />
  </svg>
);

const TrashIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={20} height={20} color="red" fill="none" {...props}>
    <path d="M19.5 5.5L18.8803 15.5251C18.7219 18.0864 18.6428 19.3671 18.0008 20.2879C17.6833 20.7431 17.2747 21.1273 16.8007 21.416C15.8421 22 14.559 22 11.9927 22C9.42312 22 8.1383 22 7.17905 21.4149C6.7048 21.1257 6.296 20.7408 5.97868 20.2848C5.33688 19.3626 5.25945 18.0801 5.10461 15.5152L4.5 5.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M3 5.5H21M16.0557 5.5L15.3731 4.09173C14.9196 3.15626 14.6928 2.68852 14.3017 2.39681C14.215 2.3321 14.1231 2.27454 14.027 2.2247C13.5939 2 13.0741 2 12.0345 2C10.9688 2 10.436 2 9.99568 2.23412C9.8981 2.28601 9.80498 2.3459 9.71729 2.41317C9.32164 2.7167 9.10063 3.20155 8.65861 4.17126L8.05292 5.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M9.5 16.5L9.5 10.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M14.5 16.5L14.5 10.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
  </svg>
);