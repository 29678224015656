import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Axios } from '../../../config';


export const addBan = createAsyncThunk('ban/addBan', async (newBanned,thunkAPI) => {
  
  try {
    const response = await Axios.post(`/bannedIp/ban`, JSON.stringify(newBanned), {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    // console.log('data', response);
  
    const { data } = response; // Utilisez la déstructuration pour extraire la propriété `data`
    return { ...data, newBanned };
  } catch (e) {
    if (e.response.status === 409) {
      return thunkAPI.rejectWithValue(e.response.data);
    } else {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue({ message: 'Server Error' });
    }
  }
  
});
export const addWhiteIp= createAsyncThunk('ban/addWhite', async (newWhiteIp,thunkAPI) => {
  
  try {
    const response = await Axios.post(`/bannedIp/addwhitelist`, JSON.stringify(newWhiteIp), {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    // console.log('data', response);
  
    const { data } = response; // Utilisez la déstructuration pour extraire la propriété `data`
    return { ...data, newWhiteIp };
  } catch (e) {
    if (e.response.status === 409) {
      return thunkAPI.rejectWithValue(e.response.data);
    } else {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue({ message: 'Server Error' });
    }
  }
  
});
export const addBlockedCountry= createAsyncThunk('ban/blockCountry', async (newBlockCountry,thunkAPI) => {
  
  try {
    const response = await Axios.post(`/bannedIp/block-country`, JSON.stringify(newBlockCountry), {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    // console.log('data', response);
  
    const { data } = response; // Utilisez la déstructuration pour extraire la propriété `data`
    return { ...data, newBlockCountry };
  } catch (e) {
    if (e.response.status === 409) {
      return thunkAPI.rejectWithValue(e.response.data);
    } else {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue({ message: 'Server Error' });
    }
  }
  
});
export const addWhitedCountry= createAsyncThunk('ban/whiteCountry', async (newWhiteCountry,thunkAPI) => {
  
  try {
    const response = await Axios.post(`/bannedIp/white-country`, JSON.stringify(newWhiteCountry), {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    // console.log('data', response);
  
    const { data } = response; // Utilisez la déstructuration pour extraire la propriété `data`
    return { ...data, newWhiteCountry };
  } catch (e) {
    if (e.response.status === 409) {
      return thunkAPI.rejectWithValue(e.response.data);
    } else {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue({ message: 'Server Error' });
    }
  }
  
});
export const getBan = createAsyncThunk('fetch/fetchBan', async ({siteId,userRole}) => {
  try {
    let url = `/bannedIp/banList?userRole=${userRole}`;
    if ( siteId) {
      // If the user is a Client, siteId is required to be added
      url += `&siteId=${siteId}`;
    }
    const res = await Axios.get(url);

    return res.data;
  } catch (error) {
    throw Error(error.response.data);
  }
});
export const getBlockCounty = createAsyncThunk('fetch/fetchBlockCountry', async ({siteId}) => {
  try {
    let url = `/bannedIp/getBlockedCountrie?siteId=${siteId}`;
   
    const res = await Axios.get(url);

    return res.data;
  } catch (error) {
    throw Error(error.response.data);
  }
});
export const getWhiteCounty = createAsyncThunk('fetch/fetchWhiteCountry', async ({siteId}) => {
  try {
    let url = `/bannedIp/getWhitedCountrie?siteId=${siteId}`;
   
    const res = await Axios.get(url);

    return res.data;
  } catch (error) {
    throw Error(error.response.data);
  }
});
export const getBanAdmin = createAsyncThunk('fetch/fetchBanAdmin', async ({siteId,userRole}) => {
  try {
    let url = `/bannedIp/banListAdmin?userRole=${userRole}`;
    if (userRole === 'Admin' && siteId) {
      url += `&siteId=${siteId}`;}
   
      
    let res = await Axios.get(url);
    return res.data;
  } catch (error) {
    throw Error(error.response.data);
  }
});
export const getWhiteAdmin = createAsyncThunk('fetch/fetchWhiteAdmin', async ({siteId,userRole}) => {
  try {
    let url = `/bannedIp/whiteListAdmin?userRole=${userRole}`;
    if (userRole === 'Admin' && siteId) {
      url += `&siteId=${siteId}`;}
   
      
    let res = await Axios.get(url);
    return res.data;
  } catch (error) {
    throw Error(error.response.data);
  }
});
export const getWhiteCountryAdmin = createAsyncThunk('fetch/fetchWhiteCountryAdmin', async ({siteId,userRole}) => {
  try {
    let url = `/bannedIp/whiteCountryListAdmin?userRole=${userRole}`;
    if (userRole === 'Admin' && siteId) {
      url += `&siteId=${siteId}`;}
   
      
    let res = await Axios.get(url);
    return res.data;
  } catch (error) {
    throw Error(error.response.data);
  }
});
export const getBannedCountryAdmin = createAsyncThunk('fetch/fetchBannedCountryAdmin', async ({siteId,userRole}) => {
  try {
    let url = `/bannedIp/banCountryListAdmin?userRole=${userRole}`;
    if (userRole === 'Admin' && siteId) {
      url += `&siteId=${siteId}`;}
   
      
    let res = await Axios.get(url);
    return res.data;
  } catch (error) {
    throw Error(error.response.data);
  }
});
export const getWhite = createAsyncThunk('fetch/fetchWhiteIp', async ({siteId,userRole}) => {
  try {
    let url = `/bannedIp/whiteList?userRole=${userRole}`;
    if ( siteId) {
      // If the user is a Client, siteId is required to be added
      url += `&siteId=${siteId}`;
    }
    const res = await Axios.get(url);

    return res.data;
  } catch (error) {
    throw Error(error.response.data);
  }
});

export const banToWhitelist = createAsyncThunk('ban/banToWhitelist', async (payload, thunkAPI) => {
  try {
    const response = await Axios.post(`/bannedIp/addbantowhite`, JSON.stringify(payload), {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    return { ...response.data, ipToMove: payload };
  } catch (error) {
    // Check if the response exists and has a data property; otherwise, use a generic error message.
    const errorMessage = error.response && error.response.data && error.response.data.message
      ? error.response.data.message
      : 'An unexpected error occurred during the IP deletion process.';
    return thunkAPI.rejectWithValue({ message: errorMessage });
  }
});

export const whiteToBanList = createAsyncThunk('ban/whiteToBanList', async (payload, thunkAPI) => {
  try {
    const response = await Axios.post(`/bannedIp/addwhitetoban`, JSON.stringify(payload), {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    return { ...response.data, ipToMove: payload };
  } catch (error) {
    // Check if the response exists and has a data property; otherwise, use a generic error message.
    const errorMessage = error.response && error.response.data && error.response.data.message
      ? error.response.data.message
      : 'An unexpected error occurred during the IP deletion process.';
    return thunkAPI.rejectWithValue({ message: errorMessage });
  }
});
export const whiteToBanCountryList = createAsyncThunk('ban/whiteToBanCountyList', async (payload, thunkAPI) => {
  try {
    const response = await Axios.post(`/bannedIp/addwhiteCountrytoban`, JSON.stringify(payload), {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    return { ...response.data, countryToMove: payload };
  } catch (error) {
    // Check if the response exists and has a data property; otherwise, use a generic error message.
    const errorMessage = error.response && error.response.data && error.response.data.message
      ? error.response.data.message
      : 'An unexpected error occurred during the IP deletion process.';
    return thunkAPI.rejectWithValue({ message: errorMessage });
  }
});
export const banToWhiteCountryList = createAsyncThunk('ban/banToWhiteCountyList', async (payload, thunkAPI) => {
  try {
    const response = await Axios.post(`/bannedIp/addbanCountrytowhite`, JSON.stringify(payload), {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    return { ...response.data, countryToMove: payload };
  } catch (error) {
    // Check if the response exists and has a data property; otherwise, use a generic error message.
    const errorMessage = error.response && error.response.data && error.response.data.message
      ? error.response.data.message
      : 'An unexpected error occurred during the IP deletion process.';
    return thunkAPI.rejectWithValue({ message: errorMessage });
  }
});


export const deleteBan = createAsyncThunk('delete/ban', async ({ ip, siteId,userRole }, thunkAPI) => {
  try {
    let res = await Axios.delete(`/bannedIp/deletebanIp`, { data: { ip, siteId,userRole } });
    return res.data;
  } catch (error) {
    // Check if the response exists and has a data property; otherwise, use a generic error message.
    const errorMessage = error.response && error.response.data && error.response.data.message
      ? error.response.data.message
      : 'An unexpected error occurred during the IP deletion process.';
    return thunkAPI.rejectWithValue({ message: errorMessage });
  }
});
export const deleteBannedCounty = createAsyncThunk('delete/bannedCountry', async ({ country, siteId,userRole }, thunkAPI) => {
  try {
    let res = await Axios.delete(`/bannedIp/deletebannedCountry`, { data: { country, siteId,userRole } });
    return res.data;
  } catch (error) {
    // Check if the response exists and has a data property; otherwise, use a generic error message.
    const errorMessage = error.response && error.response.data && error.response.data.message
      ? error.response.data.message
      : 'An unexpected error occurred during the IP deletion process.';
    return thunkAPI.rejectWithValue({ message: errorMessage });
  }
});
export const deleteWhitedCounty = createAsyncThunk('delete/whitedCountry', async ({ country, siteId,userRole }, thunkAPI) => {
  try {
    let res = await Axios.delete(`/bannedIp/deletewhitedCountry`, { data: { country, siteId ,userRole} });
    return res.data;
  } catch (error) {
    // Check if the response exists and has a data property; otherwise, use a generic error message.
    const errorMessage = error.response && error.response.data && error.response.data.message
      ? error.response.data.message
      : 'An unexpected error occurred during the IP deletion process.';
    return thunkAPI.rejectWithValue({ message: errorMessage });
  }
});


export const deleteWhiteIp = createAsyncThunk('delete/white', async ({ ip, siteId,userRole },thunkAPI) => {
  try {
    let res = await Axios.delete(`/bannedIp/deletewhiteIp`, { data: { ip, siteId,userRole } });
    return res.data;
  } catch (error) {
    // Check if the response exists and has a data property; otherwise, use a generic error message.
    const errorMessage = error.response && error.response.data && error.response.data.message
      ? error.response.data.message
      : 'An unexpected error occurred during the IP deletion process.';
    return thunkAPI.rejectWithValue({ message: errorMessage });
  }
});

const  initialState= {
  isSuccess: false,
  isError: false,
  isFetching: false,
  isSuccessAdd: false,
  isErrorAdd: false,
  isFetchingAdd: false,
  isSuccessAddWhite: false,
  isFetchingAddToWhite:false,
  isSuccessAddToWhite:false,
  isErrorAddToWhite:false,
  isSuccessGetBan:false,
  isErrorAddWhite: false,
  isFetchingAddWhite: false,
  isSuccessAddToBan:false,
  isErrorAddToBan:false,
  isFetchingAddToBan:false,
  messageError:'',
  ban: [], // initialiser ban comme un tableau vide
  whiteIp:[],
  isFetchingWhite:false,
  isFetchingBan:false,
  isFetchingBanAdmin:false,
  isErrorAdmin:false,
  isSuccessGetBanAdmin:false,
  banAdmin: [], // initialiser ban comme un tableau vide
  isFetchingWhiteAdmin:false,
  isErrorWhiteAdmin:false,
  isSuccessGetWhiteAdmin:false,
  whiteAdmin: [], // initialiser ban comme un tableau vide
banListByDate:[],
isFetchingBanByDate:false,
isErrorBanByDate:false,
isSuccessBanByDate:false,
blockCountry:[],
isFetchingBlockedCountry:false,
isErrorBlockedCountry:false,
isSuccessBlockedCountry:false,
whiteCountry:[],
isFetchingWhiteCountry:false,
isErrorWhiteCountry:false,
isSuccessWhiteCountry:false,
}
export const banAutomatiqueSlice = createSlice({
    name: 'ban',
    initialState,
    reducers: { clearBanData:  () => initialState},
    extraReducers: (builder) => {
      builder
        .addCase(addBan.pending, (state) => {
          state.isFetchingAdd = true;
          state.isSuccessAddToWhite = false;
          state.isErrorAdd = false;
          state.isSuccessAdd = false;})
      
        .addCase(addBan.fulfilled, (state, { payload }) => {
          state.isFetchingAdd = false;
          state.ban = payload;
         state.isSuccessAdd=true;
         state.isSuccessAddToWhite = false;
         state.isErrorAdd=false
          })
          .addCase(addBan.rejected, (state, { payload }) => {
            state.isFetchingAdd = false;
            state.isErrorAdd = true;
            state.isSuccessAdd = false;
            state.messageError=payload.message
          })
          .addCase(addWhiteIp.pending, (state) => {
            state.isFetchingAddWhite = true;
            state.isSuccessAddToWhite = false;
            state.isErrorAddWhite = false;
            state.isSuccessAddWhite = false;})
        
          .addCase(addWhiteIp.fulfilled, (state, { payload }) => {
            state.isFetchingAddWhite = false;
            state.whiteIp = payload;
           state.isSuccessAddWhite=true;
           state.isErrorAddWhite=false
            })
            .addCase(addWhiteIp.rejected, (state, { payload }) => {
              state.isFetchingAddWhite = false;
              state.isErrorAddWhite = true;
              state.isSuccessAddWhite = false;
              state.messageError=payload.message
            })
            .addCase(banToWhitelist.pending, (state) => {
              state.isFetchingAddToWhite = true;
              state.isErrorAddToWhite = false;
              state.isSuccessAddToWhite = false;
            })
            .addCase(banToWhitelist.fulfilled, (state, { payload }) => {
              state.isFetchingAddToWhite = false;
              state.whiteIp = payload;
              state.isSuccessAddToWhite = true;
              state.isErrorAddToWhite = false;
            })
            .addCase(banToWhitelist.rejected, (state, { payload }) => {
              state.isFetchingAddToWhite = false;
              state.isErrorAddToWhite = true;
              state.isSuccessAddToWhite = false;
              state.messageError = payload.message;
            })
            .addCase(whiteToBanList.pending, (state) => {
              state.isFetchingAddToBan = true;
              state.isErrorAddToBan = false;
              state.isSuccessAddToWhite = false;
              state.isSuccessAddToBan = false;
            })
            .addCase(whiteToBanList.fulfilled, (state, { payload }) => {
              state.isFetchingAddToBan = false;
              state.ban = payload;
              state.isSuccessAddToBan = true;
              state.isErrorAddToBan = false;
            })
            .addCase(whiteToBanList.rejected, (state, { payload }) => {
              state.isFetchingAddToBan = false;
              state.isErrorAddToBan = true;
              state.isSuccessAddToBan = false;
              state.messageError = payload.message;
            })
          .addCase(getBan.pending, (state) => {
            state.isFetchingBan = true;
            state.isSuccessAddToWhite = false;
            state.isError = false;
            state.isSuccessGetBan = false;
          state.isSuccessAdd=false;})
        
          .addCase(getBan.fulfilled, (state, { payload }) => {
            state.isFetchingBan = false;
            state.ban = payload;
           state.isSuccessGetBan=true;
           state.isError=false
            })
            .addCase(getBan.rejected, (state, { payload }) => {
              state.isFetchingBan = false;
              state.isError = true;
              state.isSuccessGetBan = false;
            })
          .addCase(getBlockCounty.pending, (state) => {
            state.isFetchingBlockedCountry=true;
            state.isErrorBlockedCountry=false;
            state.isSuccessBlockedCountry=false;
        })
        
          .addCase(getBlockCounty.fulfilled, (state, { payload }) => {
            state.blockCountry=payload;
            state.isFetchingBlockedCountry=false;
            state.isErrorBlockedCountry=false;
            state.isSuccessBlockedCountry=true;
            })
            .addCase(getBlockCounty.rejected, (state, { payload }) => {
              state.isFetchingBlockedCountry=false;
              state.isErrorBlockedCountry=true;
              state.isSuccessBlockedCountry=false;
            })
          .addCase(getBannedCountryAdmin.pending, (state) => {
            state.isFetchingBlockedCountry=true;
            state.isErrorBlockedCountry=false;
            state.isSuccessBlockedCountry=false;
        })
        
          .addCase(getBannedCountryAdmin.fulfilled, (state, { payload }) => {
            state.blockCountry=payload;
            state.isFetchingBlockedCountry=false;
            state.isErrorBlockedCountry=false;
            state.isSuccessBlockedCountry=true;
            })
            .addCase(getBannedCountryAdmin.rejected, (state, { payload }) => {
              state.isFetchingBlockedCountry=false;
              state.isErrorBlockedCountry=true;
              state.isSuccessBlockedCountry=false;
            })
          .addCase(banToWhiteCountryList.pending, (state) => {
            state.isFetchingBlockedCountry=true;
            state.isErrorBlockedCountry=false;
            state.isSuccessBlockedCountry=false;
        })
        
          .addCase(banToWhiteCountryList.fulfilled, (state, { payload }) => {
            state.blockCountry=payload;
            state.isFetchingBlockedCountry=false;
            state.isErrorBlockedCountry=false;
            state.isSuccessBlockedCountry=true;
            })
            .addCase(banToWhiteCountryList.rejected, (state, { payload }) => {
              state.isFetchingBlockedCountry=false;
              state.isErrorBlockedCountry=true;
              state.isSuccessBlockedCountry=false;
            })
          .addCase(deleteBannedCounty.pending, (state) => {
            state.isFetchingBlockedCountry=true;
            state.isErrorBlockedCountry=false;
            state.isSuccessBlockedCountry=false;
        })
        
          .addCase(deleteBannedCounty.fulfilled, (state, { payload }) => {
            state.blockCountry=payload;
            state.isFetchingBlockedCountry=false;
            state.isErrorBlockedCountry=false;
            state.isSuccessBlockedCountry=true;
            })
            .addCase(deleteBannedCounty.rejected, (state, { payload }) => {
              state.isFetchingBlockedCountry=false;
              state.isErrorBlockedCountry=true;
              state.isSuccessBlockedCountry=false;
            })
          .addCase(getWhiteCounty.pending, (state) => {
            state.isFetchingWhiteCountry=true;
            state.isErrorWhiteCountry=false;
            state.isSuccessWhiteCountry=false;
        })
        
          .addCase(getWhiteCounty.fulfilled, (state, { payload }) => {
            state.whiteCountry=payload;
            state.isFetchingWhiteCountry=false;
            state.isErrorWhiteCountry=false;
            state.isSuccessWhiteCountry=true;
            })
            .addCase(getWhiteCounty.rejected, (state, { payload }) => {
              state.isFetchingBlockedCountry=true;
              state.isErrorBlockedCountry=false;
              state.isSuccessBlockedCountry=false;
            })
          .addCase(getWhiteCountryAdmin.pending, (state) => {
            state.isFetchingWhiteCountry=true;
            state.isErrorWhiteCountry=false;
            state.isSuccessWhiteCountry=false;
        })
        
          .addCase(getWhiteCountryAdmin.fulfilled, (state, { payload }) => {
            state.whiteCountry=payload;
            state.isFetchingWhiteCountry=false;
            state.isErrorWhiteCountry=false;
            state.isSuccessWhiteCountry=true;
            })
            .addCase(getWhiteCountryAdmin.rejected, (state, { payload }) => {
              state.isFetchingBlockedCountry=true;
              state.isErrorBlockedCountry=false;
              state.isSuccessBlockedCountry=false;
            })
          .addCase(whiteToBanCountryList.pending, (state) => {
            state.isFetchingWhiteCountry=true;
            state.isErrorWhiteCountry=false;
            state.isSuccessWhiteCountry=false;
        })
        
          .addCase(whiteToBanCountryList.fulfilled, (state, { payload }) => {
            state.whiteCountry=payload;
            state.isFetchingWhiteCountry=false;
            state.isErrorWhiteCountry=false;
            state.isSuccessWhiteCountry=true;
            })
            .addCase(whiteToBanCountryList.rejected, (state, { payload }) => {
              state.isFetchingBlockedCountry=true;
              state.isErrorBlockedCountry=false;
              state.isSuccessBlockedCountry=false;
            })
          .addCase(deleteWhitedCounty.pending, (state) => {
            state.isFetchingWhiteCountry=true;
            state.isErrorWhiteCountry=false;
            state.isSuccessWhiteCountry=false;
        })
        
          .addCase(deleteWhitedCounty.fulfilled, (state, { payload }) => {
            state.whiteCountry=payload;
            state.isFetchingWhiteCountry=false;
            state.isErrorWhiteCountry=false;
            state.isSuccessWhiteCountry=true;
            })
            .addCase(deleteWhitedCounty.rejected, (state, { payload }) => {
              state.isFetchingBlockedCountry=true;
              state.isErrorBlockedCountry=false;
              state.isSuccessBlockedCountry=false;
            })
          .addCase(addBlockedCountry.pending, (state) => {
            state.isFetchingBlockedCountry=true;
            state.isErrorBlockedCountry=false;
            state.isSuccessBlockedCountry=false;
        })
        
          .addCase(addBlockedCountry.fulfilled, (state, { payload }) => {
            state.blockCountry=payload;
            state.isFetchingBlockedCountry=false;
            state.isErrorBlockedCountry=false;
            state.isSuccessBlockedCountry=true;
            })
            .addCase(addBlockedCountry.rejected, (state, { payload }) => {
              state.isFetchingBlockedCountry=false;
              state.isErrorBlockedCountry=true;
              state.isSuccessBlockedCountry=false;
            })
          .addCase(addWhitedCountry.pending, (state) => {
            state.isFetchingWhiteCountry=true;
            state.isErrorWhiteCountry=false;
            state.isSuccessWhiteCountry=false;
        })
        
          .addCase(addWhitedCountry.fulfilled, (state, { payload }) => {
            state.whiteCountry=payload;
            state.isFetchingWhiteCountry=false;
            state.isErrorWhiteCountry=false;
            state.isSuccessWhiteCountry=true;
            })
            .addCase(addWhitedCountry.rejected, (state, { payload }) => {
              state.isFetchingBlockedCountry=true;
              state.isErrorBlockedCountry=false;
              state.isSuccessBlockedCountry=false;
            })
        
          .addCase(getBanAdmin.pending, (state) => {
            state.isFetchingBanAdmin = true;
            state.isSuccessAddToWhite = false;
            state.isErrorAdmin = false;
            state.isSuccessGetBanAdmin = false;
          state.isSuccessAdd=false;})
        
          .addCase(getBanAdmin.fulfilled, (state, { payload }) => {
            state.isFetchingBanAdmin = false;
            state.banAdmin = payload;
           state.isSuccessGetBanAdmin=true;
           state.isErrorAdmin=false
            })
            .addCase(getBanAdmin.rejected, (state, { payload }) => {
              state.isFetchingBanAdmin = false;
              state.isErrorAdmin = true;
              state.isSuccessGetBanAdmin = false;
            })
          .addCase(getWhiteAdmin.pending, (state) => {
            state.isFetchingWhiteAdmin = true;
            state.isSuccessAddToWhite = false;
            state.isErrorWhiteAdmin = false;
            state.isSuccessGetWhiteAdmin = false;
          state.isSuccessAdd=false;})
        
          .addCase(getWhiteAdmin.fulfilled, (state, { payload }) => {
            state.isFetchingWhiteAdmin = false;
            state.whiteAdmin = payload;
           state.isSuccessGetWhiteAdmin=true;
           state.isErrorWhiteAdmin=false
            })
            .addCase(getWhiteAdmin.rejected, (state, { payload }) => {
              state.isFetchingWhiteAdmin = false;
              state.isErrorWhiteAdmin = true;
              state.isSuccessGetWhiteAdmin = false;
            })
          .addCase(getWhite.pending, (state) => {
            state.isFetchingWhite = true;
            state.isSuccessAddToWhite = false;
            state.isError = false;
            state.isSuccess = false;})
        
          .addCase(getWhite.fulfilled, (state, { payload }) => {
            state.isFetchingWhite = false;
            state.whiteIp = payload;
           state.isSuccess=true;
           state.isSuccessAddToWhite = false;

           state.isError=false
            })
            .addCase(getWhite.rejected, (state, { payload }) => {
              state.isFetchingWhite = false;
              state.isError = true;
              state.isSuccess = false;
            })
            .addCase(deleteBan.pending, (state) => {
                state.isFetching = true;
                state.isSuccessAddToWhite = false;
                state.isError = false;
                state.isSuccess = false;})
            
              .addCase(deleteBan.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.ban = payload;
               state.isSuccess=true;
               state.isError=false
               
                })
                .addCase(deleteBan.rejected, (state, { payload }) => {
                  state.isFetching = false;
                  state.isError = true;
                  state.isSuccess = false;
                  state.messageError=payload.message

                })
            .addCase(deleteWhiteIp.pending, (state) => {
                state.isFetching = true;
                state.isSuccessAddToWhite = false;
                state.isError = false;
                state.isSuccess = false;})
            
              .addCase(deleteWhiteIp.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.whiteIp = payload;
               state.isSuccess=true;
               state.isError=false
                })
                .addCase(deleteWhiteIp.rejected, (state, { payload }) => {
                  state.isFetching = false;
                  state.isError = true;
                  state.isSuccess = false;
                  state.messageError=payload.message

                })
         
        }})
    
        export const { clearBanData } = banAutomatiqueSlice.actions;

export default banAutomatiqueSlice.reducer;