import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {
  Card,
  CardBody,
  Col,
  Row
} from 'reactstrap';
import Logosign from '../../assets/images/logos/Sign-up-amico.svg';
import useTranslateTexts from '../../Context/useTranslateTexts';
import SecurasLogo from '../../layouts/logo/SecurasLogo';
import ResetPassword from './ResetPassword';
import SelectMethod from './forgot-password/SelectMethod';
import TOTP from './forgot-password/TOTP';
import BackupCodes from './forgot-password/BackupCodes';
import PhoneOTP from './forgot-password/PhoneOTP';
import RecoveryEmail from './forgot-password/RecoveryEmail';
import Email from './forgot-password/Email';

const ForgotPassword = () => {
  const { i18n } = useTranslation()
  const [method, setMethod] = useState("select")
  const [resetMethods, setResetMethods] = useState(null)
  const texts = {
    bie: 'Welcome!',
    con: 'Log in to access CyberShield',
    em: 'E-mail',
    pw: 'Password',
    plc: 'Enter your password',
    svm: 'Remember me',
    selsi: 'Please select site',
    cyb: ' créée avec',
    ins: 'Registration',
    comp: " Si vous n'avez pas de compte, veuillez vous inscrire",
    enc: 'En cas de soucis merci de nous contacter sur',
    conn: 'Login',
    pr: 'par',
    aleSit: 'The connection was successful',
    alec: 'Please select the website',
    aleAdd: 'Please add your website',
    emI: 'Email is invalid',
    emReq: 'Email is required',
    pass: 'Password must be at least 6 characters',
    passReq: 'Password is required',
    typS: "Type of Site",
    btn: 'Add Site',
    selec: "Select type",
    alertC: " CyberShield, Your 1st Defense Line ",
    alertA: 'Against Cyber Attacks',
    alcard: 'Start 2-Months free trial.',
    alS: 'Protect your Wordpress website now.',
    frgp: "Forgot password",
    login: "Login"

  }

  const translatedTexts = useTranslateTexts(texts);


  return (

    <div className="overflow" style={{ height: '100vh', background: '#386eaf' }}>
    <div className="p-0" style={{ height: '100vh', background: '#386eaf' }}>
      <Row className="w-100 h-100 m-0">
        <Col lg="8" md="12" className="d-flex flex-column justify-content-center d-none d-md-block" style={{ background: '#386eaf' }}>

          <div className="w-100 d-flex justify-content-center pt-4 ps-4">

            <div className="col-2">
              <SecurasLogo />
            </div>
          </div>
          <div className="text-center">
            <h1 style={{ color: "white", fontSize: '40px' }}>
              {translatedTexts.alertC} <br />
              {translatedTexts.alertA}
            </h1>
          </div>
          <div className="d-flex flex-column align-items-center mt-5">
              <div className="h-50" style={{ width: '25rem' }}>
                <img src={Logosign} alt="Logo Sign" className="h-100 w-100" />
              </div>
            </div>
            <div className="text-center mt-2">
              {/* <div className="col-9 text-center mt-2"> */}
              <h2 className='text-white text-center font-weight-bold'>
                {translatedTexts.alS} <br />
              </h2>
              <h2 className='text-white text-center font-weight-bold'>
                {translatedTexts.alcard} <br />
              </h2>
              {/* <h3>{translatedTexts.alertS}</h3> */}
              {/* </div> */}
            </div>


          </Col>
          <Col lg="4" md="12" className="d-flex flex-column justify-content-center" style={{ background: '#f1efef' }}>
            <div className="d-flex">

              <div style={{ maxWidth: '600px', margin: 'auto', width: '100%', paddingTop: '50px' }}>
                {
                  !resetMethods ?
                    <Card>
                      <CardBody className="text-center">
                        <ResetPassword setMethod={setMethod} setResetMethods={setResetMethods} />
                      </CardBody>
                    </Card>
                    : method === "select" ?
                      <Card>
                        <CardBody className="text-center">
                          <SelectMethod setMethod={setMethod} resetMethods={resetMethods} setResetMethods={setResetMethods} />
                        </CardBody>
                      </Card>
                      : method === "email" ?
                        <Card>
                          <CardBody className="text-center">
                            <Email setMethod={setMethod} resetMethods={resetMethods} setResetMethods={setResetMethods} />
                          </CardBody>
                        </Card>
                        : method === "totp" ?
                          <Card>
                            <CardBody className="text-center">
                              <TOTP setMethod={setMethod} resetMethods={resetMethods} setResetMethods={setResetMethods} />
                            </CardBody>
                          </Card>
                          : method === "backup-codes" ?
                            <Card>
                              <CardBody className="text-center">
                                <BackupCodes setMethod={setMethod} resetMethods={resetMethods} setResetMethods={setResetMethods} />
                              </CardBody>
                            </Card>

                            : method === "recovery-email" ?
                              <Card>
                                <CardBody className="text-center">
                                  <RecoveryEmail setMethod={setMethod} resetMethods={resetMethods} setResetMethods={setResetMethods} />
                                </CardBody>
                              </Card>
                              : ""
                }
              </div>
            </div>
            <Link to={`/${i18n.language}/auth/loginFormik`} className="btn btn-primary  w-50 mt-2 mx-auto" >{translatedTexts?.login}</Link>
          </Col>


        </Row>
      </div>
    </div>
  );
};

export default ForgotPassword;
