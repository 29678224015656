import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Axios } from '../../../config';

export const addBugTickets = createAsyncThunk(
  'jira/bug',
  async ({ formData }, { rejectWithValue }) => {
    try {
      // Préparer les en-têtes pour la requête 'multipart/form-data'
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data', // Cela ne doit pas être défini manuellement
          'X-Atlassian-Token': 'no-check', // Requis pour l'upload de fichiers sur Jira
        },
      };

      // Envoyer la requête avec Axios
      const response = await Axios.post(`/jira/createBug`, formData, config);

      // Vérifier la réponse et renvoyer les données
      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (error) {
      console.log('Error', error.response ? error.response.data : error);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  },
);





export const fetchIssuesById = createAsyncThunk('jira/fetchIssueById', async ({ siteId }) => {
  try {
    let res = await Axios.get(`/jira/issueslistByClient?siteId=${siteId}`);
    return res.data;
  } catch (error) {
    throw Error(error.response.data);
  }
});
export const updateIssueStatus = createAsyncThunk(
  'jira/updateIssueStatus',
  async ({ formData,numeroTicket }, { rejectWithValue }) => {
    // Your existing logic seems correct; just ensure correct data is being passed here
    try {
   
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-Atlassian-Token': 'no-check'
        }
      };

      const res = await Axios.post(`/jira/updateIssue?numeroTicket=${numeroTicket}`, formData, config);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateIssueByClient = createAsyncThunk(
  'jira/updateIssueByClient',
  async ({ formData,numeroTicket }, { rejectWithValue }) => {
    // Your existing logic seems correct; just ensure correct data is being passed here
    try {
   
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-Atlassian-Token': 'no-check'
        }
      };

      const res = await Axios.put(`/jira/updateIssueByClient?numeroTicket=${numeroTicket}`, formData, config);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const getIssueDetail = createAsyncThunk(
  'jira/getIssueDetail',
  async ({ numeroTicket }, { rejectWithValue }) => {
    try {
      const res = await Axios.get(`/jira/issuesDetail?numeroTicket=${numeroTicket}`, {
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getChangelogForIssue = createAsyncThunk(
  'jira/getChangelogForIssue',
  async ({ numeroTicket ,attachmentId}, { rejectWithValue }) => {
    try {
      const res = await Axios.get(`/jira/changelogissue?numeroTicket=${numeroTicket}`, {
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getChangelogForIssueClient = createAsyncThunk(
  'jira/getChangelogForIssueClient',
  async ({ numeroTicket ,attachmentId}, { rejectWithValue }) => {
    try {
      const res = await Axios.get(`/jira/changelogissueClient?numeroTicket=${numeroTicket}`, {
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getTransitionId = createAsyncThunk('jira/getTransitionId', async ({ numeroTicket }) => {
  try {
    let res = await Axios.get(`/jira/transitionid?numeroTicket=${numeroTicket}`);
    return res.data;
  } catch (error) {
    throw Error(error.response.data);
  }
});
export const fetchIssues = createAsyncThunk('jira/fetchIssue', async () => {
  try {
    let res = await Axios.get(`/jira/issueslist`);
    return res.data;
  } catch (error) {
    throw Error(error.response.data);
  }
});


const initialState = {
  ticketsBug: null,
  isFetchingJiraBug: false,
  isSuccessJiraBug: false,
  isErrorJiraBug: false,
  ticketsStory: null,
  isFetchingJiraStory: false,
  isSuccessJiraStory: false,
  isErrorJiraStory: false,
  issueListById: [],
  isFetchingJiraIssueById: false,
  isSuccessJiraIssueById: false,
  isErrorJiraIssueById: false,
  isFetchingJiraTransitionId: false,
  isSuccessJiraTransitionId: false,
  isErrorJiraTransitionId: false,
  isFetchingUpdateIssue: false,
  isSuccessUpdateIssue: false,
  isErrorUpdateIssue: false,
  jiraupdate: null,
  issueList: null,
  isFetchingJiraIssue: false,
  isSuccessJiraIssue: false,
  isErrorJiraIssue: false,
  issueDetail:null, 
  transitionIdList: null,
  issueChangeLog: [],
  issueChangeLogClient: [],
  issueUpdatedByClient:null

}
export const jiraTicketSlice = createSlice({
  name: 'jira',
  initialState,

  reducers: {
    clearJiraData: () => initialState},
  extraReducers: (builder) => {
    builder
      .addCase(addBugTickets.pending, (state) => {
        state.isFetchingJiraBug = true;
        state.isErrorJiraBug = false;
        state.isSuccessJiraBug = false;
      })
      .addCase(addBugTickets.fulfilled, (state, { payload }) => {
        // console.log('payload',payload)
        state.isFetchingJiraBug = false;
        state.isErrorJiraBug = false;
        state.isSuccessJiraBug = true;

      })
      .addCase(addBugTickets.rejected, (state) => {
        state.isFetchingJiraBug = false;
        state.isErrorJiraBug = true;
        state.isSuccessJiraBug = false;
      })
      
      .addCase(updateIssueStatus.pending, (state) => {
        state.isFetchingUpdateIssue = true;
        state.isErrorUpdateIssue = false;
        state.isSuccessUpdateIssue = false;
      })
      .addCase(updateIssueStatus.fulfilled, (state, { payload }) => {
        state.isFetchingUpdateIssue = false;
        state.jiraupdate = payload;
        state.isSuccessUpdateIssue = true;
        state.isErrorUpdateIssue = false;
      })
      .addCase(updateIssueStatus.rejected, (state, { payload }) => {
        state.isFetchingUpdateIssue = false;
        state.isErrorUpdateIssue = true;
        state.isSuccessUpdateIssue = false;
      })
      .addCase(updateIssueByClient.pending, (state) => {
        state.isFetchingUpdateIssue = true;
        state.isErrorUpdateIssue = false;
        state.isSuccessUpdateIssue = false;
      })
      .addCase(updateIssueByClient.fulfilled, (state, { payload }) => {
        state.isFetchingUpdateIssue = false;
        state.issueUpdatedByClient = payload;
        state.isSuccessUpdateIssue = true;
        state.isErrorUpdateIssue = false;
      })
      .addCase(updateIssueByClient.rejected, (state, { payload }) => {
        state.isFetchingUpdateIssue = false;
        state.isErrorUpdateIssue = true;
        state.isSuccessUpdateIssue = false;
      })
      .addCase(getTransitionId.pending, (state) => {
        state.isFetchingJiraTransitionId = true;
        state.isErrorJiraTransitionId = false;
        state.isSuccessUpdateIssue = false;
      })
      .addCase(getTransitionId.fulfilled, (state, { payload }) => {
        state.isFetchingJiraTransitionId = false;
        // Extrait les noms des transitions et les stocke dans un tableau
        state.transitionIdList = payload.map(t => ({
          id: t.id,
          name: t.name
        }));
        state.isSuccessJiraTransitionId = true;
        state.isErrorJiraTransitionId = false;
      })

      .addCase(getTransitionId.rejected, (state, { payload }) => {
        state.isFetchingJiraTransitionId = false;
        state.isErrorJiraTransitionId = true;
        state.isSuccessJiraTransitionId = false;
      })

  
      .addCase(fetchIssuesById.pending, (state) => {
        state.isFetchingJiraIssueById = true;
        state.isSuccessJiraIssueById = false;
        state.isSuccessJiraStory = false;
      })
      .addCase(fetchIssuesById.fulfilled, (state, { payload }) => {
        state.isFetchingJiraIssueById = false;
        state.isErrorJiraIssueById = false;
        state.isSuccessJiraIssueById = true;
        state.issueListById = payload;

      })
      .addCase(fetchIssuesById.rejected, (state) => {
        state.isFetchingJiraIssueById = false;
        state.isErrorJiraIssueById = true;
        state.isSuccessJiraIssueById = false;
      })
      .addCase(getIssueDetail.pending, (state) => {
        state.isFetchingJiraIssueById = true;
        state.isSuccessJiraIssueById = false;
        state.isSuccessJiraStory = false;
      })
      .addCase(getIssueDetail.fulfilled, (state, { payload }) => {
        state.isFetchingJiraIssueById = false;
        state.isErrorJiraIssueById = false;
        state.isSuccessJiraIssueById = true;
        state.issueDetail = payload;

      })
      .addCase(getIssueDetail.rejected, (state) => {
        state.isFetchingJiraIssueById = false;
        state.isErrorJiraIssueById = true;
        state.isSuccessJiraIssueById = false;
      })
      .addCase(getChangelogForIssue.pending, (state) => {
        state.isFetchingJiraIssueById = true;
        state.isSuccessJiraIssueById = false;
        state.isSuccessJiraStory = false;
      })
      .addCase(getChangelogForIssue.fulfilled, (state, { payload }) => {
        state.isFetchingJiraIssueById = false;
        state.isErrorJiraIssueById = false;
        state.isSuccessJiraIssueById = true;
        state.issueChangeLog = payload;

      })
      .addCase(getChangelogForIssue.rejected, (state) => {
        state.isFetchingJiraIssueById = false;
        state.isErrorJiraIssueById = true;
        state.isSuccessJiraIssueById = false;
      })
      .addCase(getChangelogForIssueClient.pending, (state) => {
        state.isFetchingJiraIssueById = true;
        state.isSuccessJiraIssueById = false;
        state.isSuccessJiraStory = false;
      })
      .addCase(getChangelogForIssueClient.fulfilled, (state, { payload }) => {
        state.isFetchingJiraIssueById = false;
        state.isErrorJiraIssueById = false;
        state.isSuccessJiraIssueById = true;
        state.issueChangeLogClient = payload;

      })
      .addCase(getChangelogForIssueClient.rejected, (state) => {
        state.isFetchingJiraIssueById = false;
        state.isErrorJiraIssueById = true;
        state.isSuccessJiraIssueById = false;
      })
      .addCase(fetchIssues.pending, (state) => {
        state.isFetchingJiraIssueById = true;
        state.isSuccessJiraIssueById = false;
        state.isSuccessJiraStory = false;
      })
      .addCase(fetchIssues.fulfilled, (state, { payload }) => {
        state.isFetchingJiraIssue = false;
        state.isErrorJiraIssue = false;
        state.isSuccessJiraIssue = true;
        state.issueList = payload;

      })
      .addCase(fetchIssues.rejected, (state) => {
        state.isFetchingJiraIssueById = false;
        state.isErrorJiraIssueById = true;
        state.isSuccessJiraIssueById = false;
      })
  }
})
export const { clearJiraData } = jiraTicketSlice.actions;

export default jiraTicketSlice.reducer;