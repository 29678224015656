import { ErrorMessage, Field, Form, Formik, useFormik } from 'formik';
import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  FormGroup,
  Label
} from 'reactstrap';
import { Axios } from '../../../config';
import useTranslateTexts from '../../../Context/useTranslateTexts';
import { formatRelativeTime } from './formatDate';
import { profileUser } from '../../../store/apps/user/userSlice';


const RecoveryEmail = ({ setMethod, twofaUser }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation()

  const [showAlertSuccess, setShowAlertSuccess] = useState(null);
  const [showAlertError, setShowAlertError] = useState(null);
  const {
    isFetchingLogin,
    isSuccessLogin,
    isErrorLogin,
    isAuthAuth,
    users,
    
    sites,
   
  } = useSelector((state) => state.userReducer);
  const formik = useFormik({
    initialValues: {
      code: ''
    },
  });
  const [codeSent, setCodeSent] = useState(false)
  const [loading, setLoading] = useState({
    sendCode: false,
  })
  const [blockedUntil, setBlockedUntil] = useState(null)

  
  
  const currentLanguage = i18n.language
  const texts = {
    enterCode: 'Veuillez entrer le code envoyé à votre email',
    verify: "Vérifier",
    invalidCode: "Code invalide",
    weWillSendCode: "Nous allons vous envoyer un code de vérification à:",
    send: "Envoyer",
    recoveryEmail: "Email de récupération",
    blocked: "Trop de tentatives de connexion, réessayer dans $1",
    invalidAttempt: "Code invalide, il vous reste $1 tentatives",
}

const translatedTexts = useTranslateTexts(texts);
const navigate = useNavigate();


function decodeHtml(html) {
    let txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}

// const validationSchema = Yup.object().shape({
//   email: Yup.string()
//     .email(decodeHtml(translatedTexts.emI))
//     .required(decodeHtml(translatedTexts.emReq))
//     .transform((value) => value.toLowerCase()), // Transformer pour mettre en minuscules
//   password: Yup.string()
//     .min(6, decodeHtml(translatedTexts.pass))
//     .required(decodeHtml(translatedTexts.passReq)),
// })
const validationSchema = undefined;

const handleSubmit = async (values, { setSubmitting }, users) => {
  setSubmitting(true);
  try{
      const res = await Axios.post('/user/loginWithRecoveryEmailOtp', {otp: values.code})
      
      await dispatch(profileUser())
      
      navigate(`/${currentLanguage}/dashboard`);
    } catch (err){
      if (err?.response?.data?.msg === "Invalid OTP"){
        setShowAlertError(decodeHtml(translatedTexts?.invalidAttempt?.replace("$1", err?.response?.data?.remainingAttempts)))
      } else if (err?.response?.data?.msg === "too many requests"){
        setBlockedUntil(err?.response?.data?.blockedUntil)
        // setShowAlertError(decodeHtml(translatedTexts?.blocked?.replace("$1", formattedTime)))
      } else {
        setShowAlertError(err?.response?.data?.msg);
      }
    }
    setSubmitting(false);
  }


  async function sendCode() {
    setLoading(prev => ({...prev, sendCode: true}))
    try {
      const res = await Axios.post('/user/sendRecoveryEmailOtp');
      setCodeSent(true)
    } catch (err) {
      console.log(err)
    }
    setLoading(prev => ({...prev, sendCode: false}))
  }
  
  useEffect(()=>{
    if (blockedUntil){
      const formattedTime = formatRelativeTime(blockedUntil)
      setShowAlertError(decodeHtml(translatedTexts?.blocked?.replace("$1", formattedTime)))
      const interval = setInterval(()=>{
        const formattedTime = formatRelativeTime(blockedUntil)
        if (new Date(blockedUntil) < new Date()){
          setBlockedUntil(null)
          setShowAlertError(null)
          clearInterval(interval)
        } else {
          setShowAlertError(decodeHtml(translatedTexts?.blocked?.replace("$1", formattedTime)))
        }
      }
      ,1000)
      return () => clearInterval(interval)
    }
  },[blockedUntil])
  
  return (
   
      <div>
        <Formik
                  initialValues={formik.initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
    {({ errors, touched, values, setFieldValue, isSubmitting }) => (
      <>
          <h3>{translatedTexts?.recoveryEmail}</h3>
              {
                codeSent ?
                <>
        <Form>
            <FormGroup>
                  <Label htmlFor="code">   {decodeHtml(translatedTexts?.enterCode)}</Label>

            <Field
                name="code"
                id="code"
                type="text"
                placeholder="XXXXXX"
                value={values.code}
                className="form-control"
                />
            <ErrorMessage name="code" component="div" className="invalid-feedback" />
            </FormGroup>
            <div className="position-relative">
              <Button
                  type="submit"
                  className="btn-block"
                  color="primary"
                  size="lg"
                  block
                  >
                  {decodeHtml(translatedTexts?.verify)}
              </Button>
              {
                isSubmitting ?
                <div className="position-absolute" style={{top: "50%", right: "20px", transform: "translateY(-50%)"}}>
                  <div className='loader-sm' ></div>
                </div>
                : ""
              }
            </div>
        </Form>
            </>
            :
            <>
            <p className='form-label'>{translatedTexts?.weWillSendCode}</p>
            <p className='px-2 py-1 border rounded fw-normal ' style={{width: "fit-content"}}>{twofaUser?.recoveryEmail}</p>
            <div className="position-relative">
              <Button
                  type="button"
                  className="btn-block"
                  color="primary"
                  size="lg"
                  block
                  onClick={sendCode}
                  >
                  {decodeHtml(translatedTexts?.send)}
              </Button>
              {
                loading.sendCode ?
                <div className="position-absolute" style={{top: "50%", right: "20px", transform: "translateY(-50%)"}}>
                  <div className='loader-sm' ></div>
                </div>
                : ""
              }
            </div>
            </>
          }
    </>
    )}
        </Formik>
        <button type='button' onClick={()=>{ setMethod("select") }} style={{all:"unset"}} className='hover:underline' >Connect with another method</button>
        {showAlertError && <p className="m-0 mt-3 p-2 alert alert-danger fw-normal" style={{fontSize: "12px"}}>{showAlertError}</p>}
        </div>
      );
};

export default RecoveryEmail;

RecoveryEmail.propTypes = {
  setMethod: PropTypes.func,
  twofaUser: PropTypes.object
}