import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Axios } from "../../../config";


export const getPattern = createAsyncThunk('pattern/getPattern', async ({ userRole }, { rejectWithValue }) => {
    try {
        const res = await Axios.get(`pattern/patternlistAdmin?userRole=${userRole}`);
        return res.data; // Assuming data contains MEMORY_INFO, STORAGE_INFO, EXTENSION, and SERVER_INFO
    } catch (error) {
        return rejectWithValue(error.response?.data || "Unknown error occurred");
    }
})
export const addPattern = createAsyncThunk(
    'pattern/addPattern',
    async ({ userRole, pattern }, { rejectWithValue }) => {
        try {
            // Send the pattern data to the server
            const response = await Axios.post(
                `pattern/addOnepattern?userRole=${userRole}`,
                { pattern },  // Sending pattern as an object
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            if (response.status === 201) {  // Adjusted to check for a 201 status (resource created)
                return response.data;
            } else {
                return rejectWithValue(response.data);
            }
        } catch (error) {
            return rejectWithValue(error.response ? error.response.data : { msg: "Network error" });
        }
    }
);
export const editPattern = createAsyncThunk(
    'pattern/editPattern',
    async ({ id, pattern, userRole }, { rejectWithValue }) => {
        try {
            const response = await Axios.put(`pattern/edit/?id=${id}&userRole=${userRole}`, { pattern }, {
                headers: { 'Content-Type': 'application/json' }
            });
            if (response.status === 200) {
                return response.data;
            } else {
                return rejectWithValue(response.data);
            }
        } catch (error) {
            return rejectWithValue(error.response ? error.response.data : { msg: "Network error" });
        }
    }
);
export const deletePattern = createAsyncThunk(
    'pattern/deletePattern',
    async ({ id, userRole }, { rejectWithValue }) => {
        try {
            const response = await Axios.delete(`pattern/delete/?id=${id}&userRole=${userRole}`);
            if (response.status === 200) {
                return response.data;
            } else {
                return rejectWithValue(response.data);
            }
        } catch (error) {
            return rejectWithValue(error.response ? error.response.data : { msg: "Network error" });
        }
    }
);

const initialState = {

    patternAdmin: null,
    isFetchingPatternAdmin: false,
    isSuccessPatternAdmin: false,
    isErrorPatternAdmin: false,
}
export const patternSlice = createSlice({
    name: 'stats',
    initialState,

    reducers: {
        clearPatternData: () => initialState
    },
    extraReducers: (builder) => {
        builder

            .addCase(getPattern.pending, (state) => {
                state.isFetchingPatternAdmin = true;
                // state.count=0;
                state.isErrorPatternAdmin = false;
                state.isSuccessPatternAdmin = false;
            })

            .addCase(getPattern.fulfilled, (state, { payload }) => {
                state.isFetchingPatternAdmin = false;
                state.patternAdmin = payload;
                state.isSuccessPatternAdmin = true;
                state.isErrorPatternAdmin = false
            })
            .addCase(getPattern.rejected, (state, { payload }) => {
                state.isFetchingPatternAdmin = false;
                state.isErrorPatternAdmin = true;
                state.isSuccessPatternAdmin = false;
            })
            .addCase(editPattern.pending, (state) => {
                state.isFetchingPatternAdmin = true;
                // state.count=0;
                state.isErrorPatternAdmin = false;
                state.isSuccessPatternAdmin = false;
            })

            .addCase(editPattern.fulfilled, (state, { payload }) => {
                state.isFetchingPatternAdmin = false;
                state.patternAdmin = payload;
                state.isSuccessPatternAdmin = true;
                state.isErrorPatternAdmin = false
            })
            .addCase(editPattern.rejected, (state, { payload }) => {
                state.isFetchingPatternAdmin = false;
                state.isErrorPatternAdmin = true;
                state.isSuccessPatternAdmin = false;
            })
            .addCase(deletePattern.pending, (state) => {
                state.isFetchingPatternAdmin = true;
                // state.count=0;
                state.isErrorPatternAdmin = false;
                state.isSuccessPatternAdmin = false;
            })

            .addCase(deletePattern.fulfilled, (state, { payload }) => {
                state.isFetchingPatternAdmin = false;
                state.patternAdmin = payload;
                state.isSuccessPatternAdmin = true;
                state.isErrorPatternAdmin = false
            })
            .addCase(deletePattern.rejected, (state, { payload }) => {
                state.isFetchingPatternAdmin = false;
                state.isErrorPatternAdmin = true;
                state.isSuccessPatternAdmin = false;
            })
            .addCase(addPattern.pending, (state) => {
                state.isFetchingPatternAdmin = true;
                // state.count=0;
                state.isErrorPatternAdmin = false;
                state.isSuccessPatternAdmin = false;
            })

            .addCase(addPattern.fulfilled, (state, { payload }) => {
                state.isFetchingPatternAdmin = false;
                state.patternAdmin = payload;
                state.isSuccessPatternAdmin = true;
                state.isErrorPatternAdmin = false
            })
            .addCase(addPattern.rejected, (state, { payload }) => {
                state.isFetchingPatternAdmin = false;
                state.isErrorPatternAdmin = true;
                state.isSuccessPatternAdmin = false;
            })

    }
})

export const { clearPatternData } = patternSlice.actions;

export default patternSlice.reducer;