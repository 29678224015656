import { ErrorMessage, Field, Form, Formik, useFormik } from 'formik';
import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  FormGroup,
  Label
} from 'reactstrap';
import { Axios } from '../../../config';
import * as Yup from 'yup';
import useTranslateTexts from '../../../Context/useTranslateTexts';
import { formatRelativeTime } from './formatDate';


const Email = ({ setMethod, resetMethods }) => {
  const {  i18n } = useTranslation()

  const [showAlertError, setShowAlertError] = useState(null);
 
  const formik = useFormik({
    initialValues: {
      code: ''
    },
  });
  const [codeSent, setCodeSent] = useState(false)
  const [loading, setLoading] = useState({
    sendCode: false,
  })
  const [blockedUntil, setBlockedUntil] = useState(null)



  const currentLanguage = i18n.language
  const texts = {
    enterCode: 'Veuillez entrer le code envoyé à votre email',
    verify: "Vérifier",
    invalidCode: "Code invalide",
    weWillSendCode: "Nous allons vous envoyer un code de vérification à:",
    send: "Envoyer",
    yourEmail: "Votre email",
    blocked: "Trop de tentatives de connexion, réessayer dans $1",
    invalidAttempt: "Code invalide, il vous reste $1 tentatives",
  }

  const translatedTexts = useTranslateTexts(texts);
  const navigate = useNavigate();


  function decodeHtml(html) {
    let txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  }


  // Schéma de validation avec Yup
  const validationSchema = Yup.object().shape({
    code: Yup.string()
      .matches(/^[0-9]{6}$/, "Le code doit contenir exactement 6 chiffres")
      .required("Le code est requis"),
  });
  const handleSubmit = async (values, { setSubmitting }, users) => {
    setSubmitting(true);
    try {
      const res = await Axios.post('/user/generateResetPasswordTokenWithEmail', { otp: values.code, email: resetMethods.email })
      console.log(res.data)
      navigate(`/${currentLanguage}/auth/reset-password/${res.data.resetToken}`);
    } catch (err) {
      if (err?.response?.data?.msg === "Invalid OTP") {
        setShowAlertError(decodeHtml(translatedTexts?.invalidAttempt?.replace("$1", err?.response?.data?.remainingAttempts)))
      } else if (err?.response?.data?.msg === "too many requests") {
        setBlockedUntil(err?.response?.data?.blockedUntil)
        // setShowAlertError(decodeHtml(translatedTexts?.blocked?.replace("$1", formattedTime)))
      } else {
        setShowAlertError(err?.response?.data?.msg);
      }
    }
    setSubmitting(false);
  }


  async function sendCode() {
    setLoading(prev => ({ ...prev, sendCode: true }))
    try {
      const res = await Axios.post('/user/sendResetPasswordEmailOtp', { email: resetMethods.email });
      setCodeSent(true)
    } catch (err) {
      console.log(err)
    }
    setLoading(prev => ({ ...prev, sendCode: false }))
  }

  useEffect(() => {
    if (blockedUntil) {
      const formattedTime = formatRelativeTime(blockedUntil)
      setShowAlertError(decodeHtml(translatedTexts?.blocked?.replace("$1", formattedTime)))
      const interval = setInterval(() => {
        const formattedTime = formatRelativeTime(blockedUntil)
        if (new Date(blockedUntil) < new Date()) {
          setBlockedUntil(null)
          setShowAlertError(null)
          clearInterval(interval)
        } else {
          setShowAlertError(decodeHtml(translatedTexts?.blocked?.replace("$1", formattedTime)))
        }
      }
        , 1000)
      return () => clearInterval(interval)
    }
  }, [blockedUntil])

  return (

    <div>
      <Formik
        initialValues={formik.initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, values, setFieldValue, isSubmitting }) => (
          <>
            <h3>{translatedTexts?.yourEmail}</h3>
            {
              codeSent ?
                <>
                  <Form>
                    <FormGroup>
                      <Label htmlFor="code">   {decodeHtml(translatedTexts?.enterCode)}</Label>

                      <Field
                        name="code"
                        id="code"
                        type="text"
                        placeholder="XXXXXX"
                        value={values.code}
                        className="form-control"
                        maxLength={6} // Limite le nombre de caractères à 6
                        inputMode="numeric"
                      />

                      <ErrorMessage name="code" component="div" className="invalid-feedback" />
                    </FormGroup>
                    <div className="position-relative">
                      <Button
                        type="submit"
                        className="btn-block"
                        color="primary"
                        size="lg"
                        block
                      >
                        {decodeHtml(translatedTexts?.verify)}
                      </Button>
                      {
                        isSubmitting ?
                          <div className="position-absolute" style={{ top: "50%", right: "20px", transform: "translateY(-50%)" }}>
                            <div className='loader-sm' ></div>
                          </div>
                          : ""
                      }
                    </div>
                  </Form>
                </>
                :
                <>
                  <p className='form-label'>{translatedTexts?.weWillSendCode}</p>
                  <p className='px-2 py-1 border rounded fw-normal ' style={{ width: "fit-content" }}>{resetMethods?.email}</p>
                  <div className="position-relative">
                    <Button
                      type="button"
                      className="btn-block"
                      color="primary"
                      size="lg"
                      block
                      onClick={sendCode}
                    >
                      {decodeHtml(translatedTexts?.send)}
                    </Button>
                    {
                      loading.sendCode ?
                        <div className="position-absolute" style={{ top: "50%", right: "20px", transform: "translateY(-50%)" }}>
                          <div className='loader-sm' ></div>
                        </div>
                        : ""
                    }
                  </div>
                </>
            }
          </>
        )}
      </Formik>
      <button type='button' onClick={() => { setMethod("select") }} style={{ all: "unset" }} className='hover:underline' >Connect with another method</button>
      {showAlertError && <p className="m-0 mt-3 p-2 alert alert-danger fw-normal" style={{ fontSize: "12px" }}>{showAlertError}</p>}
    </div>
  );
};

export default Email;

Email.propTypes = {
  setMethod: PropTypes.func,
  resetMethods: PropTypes.object
}